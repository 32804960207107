<template>
    <div id="fstyles">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-12 offset-md-2 offset-0">

                    <table class="table">

                            <tr>
                                <td class="graybg">{{tr('Passengers')}}</td>
                                <td>{{passengersNames}}</td>
                            </tr>
                    </table>

                </div>
            </div>
        </div>

        <div class="container">
            <div class="col-12">
                <h2>{{recordStore.Title}}</h2>
            </div>
        </div>

        <div class="container">
            <div class="col-12 day-it" v-for="(day,idx) of recordStore.BookingDays" v-if="!skipDays[day.DayNr]">
                <h6>
                <span v-if="!day.GroupDays">{{tr('Day')}}</span>
                <span v-else>{{tr('Days')}}</span>
                <span> {{getDayNumber(day)}}</span>
                </h6>


                <strong v-if="day.Title && day.Title.length>0">
                    {{getDatesText(day)}} - {{getCitiesNames(day)}}   –   {{day.Title}}
                </strong>
                <strong v-else>{{getDatesText(day)}} - {{getCitiesNames(day)}}</strong>
                <div v-for="(s,i) of services(day)" class="mb-3">

                    <div class="accordion accordion-flush my-2" :id="'title' + s.id" v-if="s.title && ifText(s.Description)">
                      <div class="accordion-item">
                        <div class="accordion-header cursor-pointer">
                          <div class="accordion-button collapsed d-flex"  data-toggle="collapse" :data-target="'#s' + s.id">
                            <strong>{{s.title}}</strong><span class="ml-2 underline font-italic">{{tr('read more')}}</span>
                          </div>
                        </div>
                        <div :id="'s' + s.id" class="accordion-collapse collapse mt-2" :data-parent="'#title' + s.id">
                          <div class="accordion-body">
                            <text-rows-style tag="p" :text="s.Description" v-if="s && s.Description && s.Description.length>0 && ifText(s.Description)">
                            </text-rows-style>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="ifText(s.Description)">
                        <text-rows-style tag="p" :text="s.Description" v-if="s && s.Description && s.Description.length>0 && ifText(s.Description)">
                        </text-rows-style>
                    </div>
                    <div v-else-if="s.tile">
                        <strong>{{s.title}}<br></strong>
                    </div>

                    <strong v-if="s.SupplierId && ifText(s.Description)">
                        {{tr('Local Supplier')}}: {{tr(s.Supplier.Name)}}<br>
                    </strong>
                    <strong v-else-if="s.Service && s.Service.SupplierId && ifText(s.Description)">
                        {{tr('Local Supplier')}}: {{tr(s.Service.Supplier.Name)}}<br>
                    </strong>

                    <p v-if="s.PickupInfo">
                        <strong class="pick-up" st><i>
                            PICK UP: {{s.PickupInfo}}
                        </i></strong>
                    </p>

                    <p v-if="s.ReservationNumber">
                        <strong><i>{{s.ReservationNumber}}</i></strong>
                    </p>

                    <p v-if="recordStore.ShowServiceLanguage && getLanguageOffered(s.Service)">
                        <strong>
                            {{tr('Languages')}}: {{getLanguageOffered(s.Service)}}
                        </strong>
                    </p>

                </div>

                <div v-if="getMeals(day.DayNr)">
                    <strong>
                        {{tr('Meals Included')}}: {{getMeals(day.DayNr)}}
                    </strong >
                </div>

                <div v-if="day.ifHotel">
                    <br>
                    <div v-for="row of hotels(day)">
                        <strong>{{tr('Accommodation')}}:</strong > {{row.hotel.Name}}
                        <br>
                        <div v-for="room of row.rooms">
                            <span v-if="row.Days==1">1 {{tr('Night')}} </span>
                            <span v-else-if="row.Days>1">{{row.Days}} {{tr('Nights')}}, </span>
                            {{tr('in')}} {{tr(room.Rooms)}} {{tr('Room')}}{{room.Rooms > 1 ? 's': ''}}
                            {{room.Room.Name}} {{tr(room.Category.Name)}}
                            <span v-if="room.ReservationNumber">({{room.ReservationNumber}})</span>
                        </div>
                        <div v-if="row.hotel.Checkin">Check-in: {{row.hotel.Checkin}}</div>
                        <div v-if="row.hotel.Checkout">Check-out: {{row.hotel.Checkout}}</div>
                        <br>
                    </div>
                </div>



                <div v-if="day.DocumentationText">
                    {{day.DocumentationText}}
                </div>

            </div>
        </div>

        <br>
        <br>
        <div class="container">
            <div class="col-12">
                <h4><i>{{tr('End of our services')}}</i></h4>
            </div>
            <br>
            <div class="col-12">
                <div class="box-border-top-long"></div>
            </div>

        </div>
        <br>

        <div class="container">
            <!--div class="col-12 includes">
                <h5><i>{{tr('Included')}}</i></h5>
                <text-rows-style tag="p" :text="recordStore.Include"></text-rows-style>
            </div>
            <br>
            <div class="col-12">
                <h5><i>{{tr('Not Included')}}</i></h5>
                <text-rows-style tag="p" :text="recordStore.NotInclude"></text-rows-style>
            </div>
            <br-->
            <div class="col-12" v-if="getFlightsList.length">
                <h5>{{tr('Flights')}}</h5>
                <text-rows-style tag="p" :text="f" v-for="f of getFlightsList" v-bind:key="f.id"></text-rows-style>
            </div>
            <br>
            <div class="col-12 emergency-contact">
                <h6>{{tr('Emergency contacts')}}</h6>
                <div class="info-box-body">
                    <text-rows-style tag="p" :text="recordStore.EmergencyContacts"></text-rows-style>
                </div>
            </div>
            <br>
            <div class="col-12 info-box">
                <h6>{{tr('Useful Information')}}</h6>
                <div class="info-box-body">
                    <br>
                    <b><i>{{tr('Our representatives in')}}...</i></b>
                    <ul>
                        <li v-for="supplier of getSuppliers" class="info-box-row">
                            <div>{{supplier.City}} - <i>{{supplier.Supplier.Name}}</i></div>
                            <div>{{tr('Address')}}: {{supplier.Supplier.Address}}</div>
                            <div>{{tr('Phone Number')}}: {{supplier.Supplier.Phone}}</div>
                        </li>
                    </ul>
                    <br>
                    <b><i>{{tr('Accommodation during your trip')}}...</i></b>
                    <ul>
                        <li v-for="hotel of getHotels" class="info-box-row">
                            <div>{{hotel.City}} - <i>{{hotel.Hotel.Name}}</i></div>
                            <div>{{tr('Address')}}: {{hotel.Hotel.Address}}</div>
                            <div>{{tr('Phone Number')}}: {{hotel.Hotel.Phone}}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container mt-5">
            <div class="col-12 includes mb-5">
                <h5>{{tr('Suggestions for your Trip')}}.</h5>
                <h5>{{tr('Please find below useful information to get the most of your stay')}}.</h5>
            </div>
            <div class="col-12 includes" v-for="c of bookingCitiesObject">
                <h5><b>{{c.Name}}</b></h5>
                <text-rows-style :text="getCityDescription(c)"></text-rows-style>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-final-preview',
    data () {
        return {
            passengerList: []
        }
    },
    async mounted () {
        this.passengerList = await this.getPassengers();
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        passengersNames () {
            if (this.passengerList.length>0) {
                return this.passengerList.join(' / ');
            }
            return this.recordStore.PaxName;
        },
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        getIncludes () {
            if (!this.recordStore.Include) return [];
            return this.recordStore.Include.split('\n');
        },
        getNotIncludes () {
            if (!this.recordStore.NotInclude) return [];
            return this.recordStore.NotInclude.split('\n');
        },
        langCode () {
           let r = _.find(api.tables.language, (c) => c.id == this.recordStore.LanguageId)
           if (r) return r.Code;
        },
        rooms (){
            let r = [];
            for (let d of this.recordStore.BookingDays) {
                if (d.BookingDayHotels[0] && d.BookingDayHotels[0].BookingDayRooms) {
                    for (let room of d.BookingDayHotels[0].BookingDayRooms) {
                        //let room = d.BookingDayHotels[0].BookingDayRooms[i];
                        if (room.Room && room.Room.Checkin) continue;
                        if (r.indexOf(this.tr(room.Room.Name))==-1) {
                            r.push(this.tr(room.Room.Name));
                        }
                    }
                }
            }
            return r.join(' / ');
        },
        getSuppliers () {
            let res = {}
            let suppliers = []
            let cityOrder = {};
            let k = 1;
            if (this.recordStore && this.recordStore.BookingDays) {
                for (let day of this.recordStore.BookingDays) {
                    for (let service of day.BookingDayServices) {
                        if (service.Status == 'CANCELED') continue;
                        if (service.Supplier && service.Supplier.id) {
                            if (!res[service.Supplier.id]) {
                                res[service.Supplier.id] = service.Supplier.id;
                                if (!cityOrder[service.CityId]) {
                                    cityOrder[service.CityId] = k;
                                    k += 1;
                                }
                                suppliers.push({Supplier: service.Supplier, City: service.City.Name,
                                    CityOrder: cityOrder[service.CityId]})
                            }
                        }
                    }
                }
            }
            suppliers.sort((a,b) => {
                 if (a.CityOrder > b.CityOrder) return 1;
                 if (a.CityOrder < b.CityOrder) return -1;
                 return 0;
            });
            return suppliers;
        },
        getHotels () {
            let res = {};
            let hotels = []
            let cityOrder = {};
            let k = 1;
            for (let day of this.recordStore.BookingDays) {
                if (day.BookingDayHotels[0]) {
                    for (let room of day.BookingDayHotels[0].BookingDayRooms) {
                        //let room = day.BookingDayHotels[0].BookingDayRooms[i];
                        if (!room.Hotel) continue;
                        if (!room.Hotel.id) continue;
                        if (!res[room.Hotel.id]) {
                            if (!cityOrder[room.Hotel.CityId]) {
                                cityOrder[room.Hotel.CityId] = k;
                                k += 1;
                            }
                            res[room.Hotel.id] = room.Hotel.id;
                            hotels.push({Hotel: room.Hotel, City: room.Hotel.City.Name,
                                CityOrder: cityOrder[room.Hotel.CityId]})
                        }
                    }
                }
            }
            hotels.sort((a,b) => {
                 if (a.CityOrder > b.CityOrder) return 1;
                 if (a.CityOrder < b.CityOrder) return -1;
                 return 0;
            });
            return hotels;
        },
        getFlightsList () {
            let res = [];
            if (!this.recordStore.BookingFlights) return res;
            for (let f of this.recordStore.BookingFlights) {
                if (f.Output) {
                    let rows = f.Output.split('\n');
                    for (let r of rows){
                        res.push(r);
                    }
                }
            }
            return res;
        },
        skipDays () {
            return this.recordStore.skipDays;
        },
        hotelListByDate () {
            return this.recordStore.hotelListByDate;
        },
        citiesToPrint (){
            return this.recordStore.citiesToPrint();
        },
        bookingCitiesObject () {
            return Object.values(_.filter(this.recordStore.bookingCitiesObject, (c) => {
                let d =  this.getCityDescription(c);
                if (d) return tools.ifText(d);
            })).sort((a,b) => {
                 if (a.DayNr > b.DayNr) return 1;
                 if (a.DayNr < b.DayNr) return -1;
                 return 0;
            });
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getDayMonth (day){
            if (!this.langCode) return '';
            if (!day.TransDate) return '';
            return tools.toTitleCase(moment(day.TransDate).locale(this.langCode).format("MMMM"));
        },
        getDayNr (day){
            return moment(this.recordStore.StartDate).add(day.DayNr,'days').format("DD");
        },
        getDatesText (day) {
            if (day.GroupDays && day.GroupDayTo) {
                let r1 = this.getDayMonth(day) + ' ' + this.getDayNr(day);
                let r2 = '';
                let dayTo = _.find(this.recordStore.BookingDays, (p) => p.DayNr == day.GroupDayTo - 1);
                if (dayTo) {
                    r2 = this.getDayMonth(dayTo) + ' ' + this.getDayNr(dayTo);
                }
                return `${this.tr('From')} ${r1} ${this.tr('TO_')} ${r2}`;
            }
            return this.getDayMonth(day) + ' ' + this.getDayNr(day);
        },
        getCities (day){
            return day.getCities(this.recordStore.hotelsByDay[day.DayNr]);
        },
        getCitiesNames (day){
            return day.getCitiesNames(this.recordStore.hotelsByDay[day.DayNr]);
        },
        getMeals (dayNr) {
            let day = _.find(this.recordStore.BookingDays, (d) => d.DayNr == dayNr);
            if (day) {
                let meals = [];
                if (day._breakfast) meals.push(this.tr('Breakfast'));
                if (day._lunch) meals.push(this.tr('Lunch'));
                if (day._dinner) meals.push(this.tr('Dinner'));
                if (day._snack) meals.push(this.tr('Snack'));
                if (day._boxLunch) meals.push(this.tr('Box Lunch'));
                if (meals.length==0) return
                let res = meals.join(', ');
                let lastC = res.lastIndexOf(', ');
                if (lastC==-1) return res
                res = res.substring(0, lastC) + ' & ' + res.substring(lastC+2, res.length);
                return res;
            }
        },
        async getPassengers () {
            let paxList = [];
            let rf = await api.get('/api/bookingform/', {filters: JSON.stringify({BookingId: this.recordStore.id })})
            if (rf && rf.length==1) {
                for (let pax of rf[0].BookingFormPaxs) {
                    let paxName = frontTools.value(pax.FirstName) + ' ' + frontTools.value(pax.LastName);
                    paxList.push(paxName);
                }
            }
            return paxList;
        },
        getLanguageOffered (service) {
            if (!service.ServiceDescriptions) return;
            let res = [];
            for (let d of service.ServiceDescriptions) {
                if (d.OfferedLanguage) {
                    let lang = _.find(this.languages, (r) => r.id == d.LanguageId);
                    if (lang) {
                        res.push(lang.Name)
                    }
                }
            }
            return res.join(', ');
        },
        getDayNumber (day) {
            if (day.GroupDays && day.GroupDayTo) {
                let r = parseInt(day.DayNr) + 1;
                return `${this.tr('_From')} ${this.tr('Day')} ${r} ${this.tr('_TO')} ${day.GroupDayTo}`;

            }
            return parseInt(day.DayNr) + 1;
        },
        ifText (s) {
            return tools.ifText(s);
        },
        getCityDescription (city) {
            if (!city) return;
            let d = _.find(city.CityDescriptions, (r) => r.LanguageId == this.recordStore.LanguageId);
            if (d) return d.Description;
        },
        ifHotel (h) {
            return h.ifHotel;
        },
        getHotelText (descriptions) {
            let t = _.find(descriptions, (c) => c.LanguageId==this.recordStore.LanguageId);
            if (t) return t.Description;
            return '';
        },
        getHotelNames (hotelInfo) {
            let res = [];
            if (hotelInfo.BookingDayRooms[0] && hotelInfo.BookingDayRooms[0].Hotel) {
                return hotelInfo.BookingDayRooms[0].Hotel.Name;
            }
            return '';
        },
        hotels (day) {
            let res = {};
            for (let hotel of day.BookingDayHotels) {
                for (let room of hotel.BookingDayRooms) {
                    if (!room.Hotel) continue;
                    if (!room.Hotel.id) continue;
                    if (!room.Hotel.RoomCategories) continue;
                    if (!room.roomQuoteSelected) continue;
                    if (!res[room.HotelId]) res[room.HotelId] = {hotel: room.Hotel, rooms: [], Days: hotel.Days }
                    res[room.HotelId].rooms.push(room)
                }
            }
            return res;
        },
        services (day) {
            let res = [];
            for (let s of day.BookingDayServices) {
                if (s.Optional) continue;
                res.push(s);
            }
           return res;
        }

    },
}
</script>

<style scoped>

.cursor-pointer:hover {
    cursor: pointer;
}
.underline {
    text-decoration: underline;
    //color: blue;
}

</style>