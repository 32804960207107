<template>
    <div class="form-material">
        <div class="row">
            <div class="col-3">
                <vue-select-input
                    label="Supplier"
                    :currentValue.sync="supplierId"
                    :fieldOptions="supplierIdOptions"
                    :addBlank="true">
                </vue-select-input>
            </div>
            <div class="col-3">
                <vue-select-input
                    label="Service Type"
                    :currentValue.sync="typeId"
                    :fieldOptions="typeIdOptions"
                    :addBlank="true">
                </vue-select-input>
            </div>
            <div class="col-3" v-if="ready">
                <button type="button" class="btn btn-outline-secondary" @click="run" :disabled="processing">
                    {{tr('Iniciar')}}
                </button>
            </div>
            <div class="col-3" v-if="end">
                <label>FIN</label>
            </div>
        </div>
        <div class="row" v-if="copyList.length>0">
            <div class="col-12" v-for="r of copyList">
                <label>{{r}}</label>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from 'vuex';
export default {
    name: 'supplier-service-copy',
    props: [ "record" ],
    components: {},
    async mounted () {
        this.supplierIdOptions = _.map(api.tables.supplier, (r) => {
            return {label: r.Name, value: r.id}
        })
        this.typeIdOptions = _.map(api.tables.servicetype, (r) => {
            return {label: r.Name, value: r.id}
        })
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        ready () {
            return this.supplierId || this.typeId;
        }
    },
    data () {
        return {
            supplierId: null,
            typeId: null,
            supplierIdOptions: [],
            typeIdOptions: [],
            copyList: [],
            end: false,
            processing: false,
        }
    },
    methods: {
        async run () {
            this.processing = true;
            this.end = false;
            this.copyList = [];
            this.copyList = Object.assign([], this.copyList);
            let services = _.filter(api.tables.service, (r) => {
                return r.SupplierId == this.record.id;
            });
            let model = api.importMixinModule('record', 'model');
            let options = await api.getModuleFieldsOptions('service');
            for (let service of services) {
                let k = this.copyList.length;
                this.copyList.push(`Copiando ${service.Name}...`);
                this.copyList = Object.assign([], this.copyList);
                let s = await api.get('/api/service/' + service.id);
                let newService = await new model({record: s, options, tableName: 'service'});
                let cl = await model.clone({record: newService});
                if (this.supplierId) cl.SupplierId = this.supplierId;
                if (this.typeId) cl.ServiceTypeId = this.typeId;
                await cl.save();
                if (cl.notifications.message) {
                    this.copyList[k] = `Copiando ${s.Name}: ${tr(cl.notifications.message)}`;
                } else if (cl.notifications.errors.length>0) {
                    let e = cl.notifications.errors.join(', ');
                    this.copyList[k] = `Copiando ${s.Name}: ${tr(e)}`;
                }
                this.copyList = Object.assign([], this.copyList);
            }
            this.supplierId = null;
            this.typeId = null;
            this.end = true;
            this.processing = false;
        }
    }
};
</script>
