
<script>
const bookingTabsContent = importVueComp('components/booking', 'BookingTabsContent', 'custom');
import importantNotes from '@/extra/custom/components/booking/ImportantNotes';
export default {
    name: 'custom-booking-tabs-content',
    extends: bookingTabsContent,
    mounted () {
        this.extraTabs.push({id: 'importantNote', component: importantNotes});
    },
}
</script>
