<template>
    <div id="fstyles">
        <div v-if="recordStore && !processing">
            <button type="button" class="btn btn-primary action-button button-fixed" @click="printPDF">
                <font-awesome-icon icon="print"/>
            </button>


            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <async-img :url="imageAgency" class="" v-if="imageAgency" width="200"></async-img>
                        <img width="200" id="logo" src="@/extra/custom/img/logo-full.png" class="" v-else>
                        <h1 class="ml-3">Itinerary & Confirmation</h1>
                    </div>
                </div>
            </div>

            <booking-final-preview
            ></booking-final-preview>
            <br>
            <!--div class="container">
                <div class="col-12 info-box">
                    <h6>{{tr('Terms & Conditions')}}</h6>
                    <div class="info-box-body">
                        <p>Following the
                        <a :href="'https://www.nubbo.io/' + langCode + '/terms-and-conditions/'" target="_blank">
                        link</a>
                        that appears below you will be able to read our terms and conditions</p>
                    </div>
                </div>
            </div-->
            <itinerary-footer></itinerary-footer>


        </div>
    </div>
</template>

<script>
let bookingFinalView = importVueComp('components/documentation', 'BookingFinalView', 'custom');
const itineraryFooter = importVueComp('components/itinerary', 'ItineraryFooter');
import '@/extra/custom/css/fstyles.scss';
export default {
    name: 'booking-final-view',
    mixins: [bookingFinalView],
    components: {
        'itinerary-footer': itineraryFooter,
    },
    computed: {
        imageAgency () {
            if (this.recordStore && this.recordStore.Agency && this.recordStore.Agency.Image) {
                return this.recordStore.Agency.Image.url;
            }
        },
    },

}
</script>

