<script>
var EventBus = require('@/tools/event-bus').default;
const bookingReceipts = importVueComp('components/booking', 'BookingReceipts', 'custom');
var html = importRawTemplate(bookingReceipts);
let div = '<div class="text-danger text-center" v-if="errorAmount"><b>{{tr("QUOTED_NOT_AMOUNT_RECEIVED")}}</b></div>';
var t1 = insertLast(html, div);

import Vue from 'vue';
let comp = Vue.component('custom-booking-receipts', {
    name: 'custom-booking-receipts',
    mixins: [bookingReceipts],
    template: t1,
    render: null,
    computed: {
        errorAmount () {
            if (!this.recordStore) return;
            if (!this.recordStore.bookingErrors) return;
            if (this.recordStore.bookingErrors.receipt_pending) return true;
        },
    },
    methods: {
        async addNew () {
            let model = await api.importMixinModule('bookingreceipt', 'model');
            let newReceipt = await new model({tableName: 'bookingreceipt'});
            newReceipt.BookingId = this.recordStore.id;
            if (this.recordStore.CompanyId) newReceipt.CompanyId = this.recordStore.CompanyId;
            EventBus.$emit('open-modal-record', {modalRecord: newReceipt, modalTable: 'bookingreceipt',
                modalId: 'new', closeAction: 'close-receipt'});
        },
    },
})
export default comp;

/*export default {
    name: 'custom-booking-receipts',
    mixins: [bookingReceipts],
    mounted () {
    },
    methods: {
        async addNew () {
            let model = await api.importMixinModule('bookingreceipt', 'model');
            let newReceipt = await new model({tableName: 'bookingreceipt'});
            newReceipt.BookingId = this.recordStore.id;
            if (this.recordStore.CompanyId) newReceipt.CompanyId = this.recordStore.CompanyId;
            EventBus.$emit('open-modal-record', {modalRecord: newReceipt, modalTable: 'bookingreceipt',
                modalId: 'new', closeAction: 'close-receipt'});
        },
    },
}*/

</script>

