const Docx = vueTools.importModule('docx', 'tools', 'custom').default;
var EventBus = require('@/tools/event-bus').default;

class CustomDocx extends Docx {

    get brandColor () {
        return '4D2765';
    }

    get textColor () {
        return '000000';
    }


    getDayYear (day) {
        return moment(day.TransDate).format("YYYY");
    }

    getDatesText (day) {
        if (day.GroupDays && day.GroupDayTo) {
            let r1 = this.getDayNr(day) + ' ' + this.getDayMonth(day) + ' ' + this.getDayYear(day);
            let r2 = '';
            let dayTo = _.find(this.booking.BookingDays, (p) => p.DayNr == day.GroupDayTo - 1);
            if (dayTo) {
                r2 = this.getDayNr(dayTo) + ' ' + this.getDayMonth(dayTo) + ' ' + this.getDayYear(dayTo);
            }
            return `${this.tr('From')} ${r1} ${this.tr('TO_')} ${r2}`;
        }
        return this.getDayNr(day) + ' ' + this.getDayMonth(day) + ' ' + this.getDayYear(day);
    }


    getDayName (day) {
        let dayNrTitle;
        let dayNrName;
        if (this.booking.ShowDates) {
            if (!day.GroupDays) {
                dayNrTitle = this.tr('Day');
            } else {
                dayNrTitle = this.tr('Days');
            }
            dayNrName = this.getDatesText(day);
            let dayNrNumber = this.getDayNumber(day);
            return `${dayNrName}, ${dayNrTitle} ${dayNrNumber}`;
        } else {
            dayNrTitle = this.tr('Day');
            dayNrName = this.getDayNumber(day);
            return `${dayNrTitle} ${dayNrName}`;
        }
    }

    getPriceTableCell () {
        if (this.booking.HideTotalPrice) return
        return super.getPriceTableCell();
    }

    getServiceObjects  (day) {
        let stacks = [];
        this.processingDayNr = day.DayNr + 1;
        EventBus.$emit('processing-day', this.processingDayNr);
        let imageAdded = false;
        for (let s of day.BookingDayServices) {
            let image;
            if (day.Image && day.Image.id && !imageAdded && s.Description) {
                image = day.Image;
                imageAdded = true;
            }
            if (!image) {
                if (s && s.BookingDayServiceImages) {
                  for (let i of s.BookingDayServiceImages) {
                      if (i.Image && i.Image.id) {
                          image = i.Image;
                          imageAdded = true;
                          break;
                      }
                  }
                }
            }
            let sTitle = this.getServiceTitle(s);
            if (sTitle) {
                stacks.push({text: sTitle, html: true, italics: false, style: 'serviceTitle'});
            }

            if (s.Optional) {
                let sTitleName = this.getServiceTitleName(s);
                stacks.push({text: sTitleName, html: true, italics: false, style: 'serviceTitle'});
                stacks.push({text: this.tr('(Optional Service)'), html: true, italics: true, style: 'serviceTitleOptional'});
            }

            stacks.push({text: s.Description, html: true, style: 'serviceText', image: {img: image}});
        }
        let res = this.getStacks(stacks);
        return res;
    }


}

export default CustomDocx;
