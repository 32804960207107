<template>
      <div v-if="ready">
        <report-window
          endpoint="/contact/reports/contact_list"
          :fields="fields"
          :title="cardTitle || title"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :columnsClasses="columnsClasses"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :headers="headers"
          :columnClick="columnClick"
          :fieldStyles="fieldStyles"
        ></report-window>
      </div>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow';
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'contact-list-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
    },
    mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            reload: false,
            ready: false,
            fields: [
                {name: 'AgencyId', label: 'Agency', editor: 'vue-select',
                  optionLabels: 'Name', relation: 'agency', addBlank: true,
                },
                {name: 'SupplierId', label: 'Supplier', editor: 'vue-select',
                  optionLabels: 'Name', relation: 'supplier', addBlank: true,
                },
                {name: 'Type', label: 'Type', editor: 'select', defValue: '1',
                    options: [
                        {value: '0', label: 'All'},
                        {value: '1', label: 'Agency'},
                        {value: '2', label: 'Supplier'},
                    ]
                },
                {
                  name: 'SalesOriginId', label: 'SalesOrigin', editor: 'vue-select',
                  optionLabels: 'Name', relation: 'salesorigin', addBlank: true,
                },
                {
                  name: 'ContactOriginId', label: 'Contact Origin', editor: 'vue-select',
                  optionLabels: 'Name', relation: 'salesorigin', addBlank: true,
                }
            ],
            title: 'Contact List',
            headers: {
                ReviewDate: 'Review',
                ID: 'Nº',
                LeadStatus: 'Status',
            },
            templates: [
            ],
            columnsClasses: {
            },
            columnClick: {
            },
            fieldStyles: {
            },
        }
    },
    methods: {
    },
}
</script>

