<template>
    <div>
          <div class="entry-meta icon-box not-break-inside col-lg-4 pl-0" v-if="getMeals(day.DayNr)">
            <i class="ri-restaurant-fill"></i>
            <div>
              <h4>{{tr('Meals Included')}}</h4>
              <p>{{getMeals(day.DayNr)}}</p>
            </div>
          </div>
    </div>
</template>

<script>
const itineraryBodyDayTypes = importVueComp('components/itinerary', 'ItineraryBodyDayTypes', 'custom');
export default {
    name: 'custom-itinerary-body-day-types',
    mixins: [itineraryBodyDayTypes],
}
</script>


