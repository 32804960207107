<template>
    <div class="col-md-3 mt-2 d-flex flex-column pl-3">
        <booking-quote-config-checkbox></booking-quote-config-checkbox>
            <div class="form-check checkbox-group">
                <input v-model="record.DisableLinks" class="form-check-input" type="checkbox"
                @change="change('DisableLinks')"
                 id="DisableLinks">
                <label for="DisableLinks" class="smaller form-check-label">{{'Deshabilitar Itinerario'}}</label>
            </div>
            <div class="form-check checkbox-group">
                <input v-model="record.DisableBreakdown" class="form-check-input" type="checkbox"
                @change="change('DisableBreakdown')"
                 id="DisableBreakdown">
                <label for="DisableBreakdown" class="smaller form-check-label">{{'Deshabilitar Breakdown'}}</label>
            </div>
    </div>
</template>

<script>
const bookingQuoteConfigCheckbox = importVueComp('components/booking', 'BookingQuoteConfigCheckbox', 'custom');
export default {
    name: 'custom-booking-quote-config-checkbox',
    mixins: [bookingQuoteConfigCheckbox],
    components: {
        'booking-quote-config-checkbox': bookingQuoteConfigCheckbox,
    },
}
</script>

