<template>
    <div id="pstyles" v-if="ready && recordStore">
        <header id="header" class="header fixed-top">
          <div class="container container-xl d-flex align-items-center justify-content-between">
            <a class="logo d-flex align-items-center">
              <img src="@/extra/custom/img/logo-itinerary.png" alt="" class="w-100">
            </a>
          </div>
        </header>
        <trip-review-details
            v-if="!recordStore.DisableLinks"
            class="breakdown"
            :id="id"
            :hash="hash"
        ></trip-review-details>
        <div class="container notes" v-if="!recordStore.DisableLinks">
            <div class="col-12" v-if="recordStore.ImportantNotes && recordStore.ImportantNotes.length > 20">
                <text-rows-style :text="recordStore.ImportantNotes">
                </text-rows-style>
            </div>
            <div class="form-row" v-else>
                <div class="col-12">
                    IMPORTANT NOTES:
                </div>
                <div class="col-12">
                • This is a quote, not a services confirmation. It does not guarantee availability at the moment of the booking request, and it is valid for 15 days from the date of the quotation.
                </div>
                <div class="col-12">
                • RATES IN USD VALID for the dates quoted except if important changes of general prices and/or variation of the USD exchange rate occur in Argentina/ Chile /Uruguay / Brazil.
                </div>
                <div class="col-12">
                • This quotation does not include local accommodation taxes (around 1-2 USD per person per night). To be paid directly at the hotel.
                </div>
                <div class="col-12">
                • Hotel rates do not include 21% VAT accommodation tax. Hotel rates are valid per foreign people applying VAT 21% exception, to honor this rate passenger should send us the scan copy of the passport at booking request and same document should be used during the check in at the hotel. Please take into account that in case of changes in the Government regulation, the VAT will be charged. Please keep your passengers aware of this situation.
                </div>
                <div class="col-12">
                • The quote is on the requested base of passengers if the basis changes the quotation will do as well.
                </div>
                <div class="col-12">
                • Covid: The current quote does not include possible extra expenses due to special social distance that the local governments may establish for tours and transfers in the future. We reserve the right to adjust the rates according to the local policies and requirements. For foreign passengers, the national government may require a travel medical insurance for COVID-19 as well as a negative PCR. For the return to the country of origin, we can assist travelers in scheduling a PCR or antigen test (with direct payment to the laboratory or pharmacy). In case the laboratory is unable to go to the traveler's hotel, we may offer local guide assistance, for a fee. The passengers could be asked at any time of their trip to show their vaccination proof, so it is important to carry it always with them.
                </div>
                <div class="col-12">
                Check Chile updated entry requirements <a class="color-blue" href="https://www.chile.travel/en/traveltochileplan/">here</a> Easter Island entry requierement could be not the same than the rest of Chile, kindly check!
                </div>
                <div class="col-12">
                Check Argentina updated entry requirements <a class="color-blue" href="https://www.argentina.travel/en/page/we-reopen-borders-for-neighboring-countries">here</a>
                </div>
            </div>
        </div>
        <div class="app-index container notes mt-5 pt-5" v-else>
            <div class="form-row mt-3">
                <div class="col-12">
                    ITINERARY DISABLED
                </div>
            </div>
        </div>
        <itinerary-footer></itinerary-footer>
    </div>
</template>

<script>
import '@/extra/custom/css/style.scss';
const itineraryFooter = importVueComp('components/itinerary', 'ItineraryFooter');
const tripReviewDetails = importVueComp('components/booking', 'TripReviewDetails');
const importantNotes = importVueComp('components/booking', 'ImportantNotes');
import { mapState } from 'vuex';
export default {
    name: 'trip-review',
    props: ['id', 'hash'],
    components: {
        'trip-review-details': tripReviewDetails,
        'itinerary-footer': itineraryFooter,
        'important-notes': importantNotes,
    },
    data () {
        return {
            ready: false,
        }
    },
    async mounted (){
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r;
        if (this.version) {
            r = await api.get('/public/api/bookingversion/' + this.hash + '/' + this.id);
        } else {
            r = await api.get('/public/api/booking/' + this.hash + '/' + this.id);
        }
        if (r) {
            r._public = true;
            let record = await new model({record: r});
            this.$store.commit('initRecord', {record});
            if (r.DisableLinks) {
                api.setProcessing(false)
            }
        }
        this.ready = true;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    methods: {
        ifText (s) {
            return tools.ifText(s);
        },
    }
}
</script>

<style scoped>
.breakdown {
 padding: 50px 0;
 width: 100%;
 min-height: 40px;
 margin-top: 62px;
 align-items: center;
 position: relative;
 //z-index: 0;
}

.notes {
 margin-bottom: 5rem;

}

</style>


