
<script>
const paymentListReport = importVueComp('components/reports', 'PaymentListReport', 'custom');
import { mapState } from 'vuex';
export default {
    name: 'custom-payment-list-report',
    mixins: [paymentListReport],
    methods: {
        getData () {
            let r = paymentListReport.methods.getData(this);
            let index = r.headerColumns.indexOf('CurrencyRate');
            r.headerColumns.splice(index + 1, 0, 'MEPCurrencyRate');
            r.headerColumns.splice(r.headerColumns.indexOf('PaxName') + 1, 0, 'Agency');
            r.headers.MEPCurrencyRate = 'Cotización MEP';
            r.slotsFields.push({name: 'MEPCurrencyRate', editor: 'number', decimal: 2, classes: 'mb-0'})
            r.fields.push({name: 'AgencyId', label: 'Agency', editor: 'select', optionLabels: 'Name', relation: 'agency', addBlank: true})
            let fromDate = _.find(r.fields, (p) => p.name == 'FromDate');
            if (fromDate) {
                fromDate.requiredCallback = (t, r) => {return !r.BookingId && !r.PaymentStatus};
            }
            let toDate = _.find(r.fields, (p) => p.name == 'ToDate');
            if (toDate) {
                toDate.requiredCallback = (t, r) => {return !r.BookingId && !r.PaymentStatus};
            }
            return r;
        }
    }
}
</script>

