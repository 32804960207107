
<script>
const salesByUserReport = importVueComp('components/reports', 'SalesByUserReport', 'custom');
export default {
    name: 'custom-sales-by-user-report',
    mixins: [salesByUserReport],
    methods: {
        getData (self) {
            let r = salesByUserReport.methods.getData(this);
            r.headerColumns.splice(r.headerColumns.indexOf('ConfirmedDate') + 1, 0, 'CreatedUTC');
            r.headerColumns.splice(r.headerColumns.indexOf('CreatedUTC') + 1, 0, 'ConfirmedTime');
            r.headers.CreatedUTC = "CreatedUTC";
            r.headers.ConfirmedTime = "ConfirmedTime";
            r.fields = Object.assign([], r.fields)
            let CreatedUTC = {
                name: "CreatedUTC",
                callback: (row) => {
                    if (!row.CreatedUTC) return "";
                    return moment(row.CreatedUTC).format("DD/MM/YYYY");
                }
            } 
            let ConfirmedTime = {
                name: "ConfirmedTime",
                callback: (row) => {
                    if (!row.ConfirmedDate || !row.CreatedUTC) return "";
                    let confirmedDate = row.ConfirmedDate;
                    let createdUTC = row.CreatedUTC
                    let confirmedTime = moment(confirmedDate).diff(createdUTC, 'days');
                    return confirmedTime + ' días';
                }
            }
            r.templates.splice(r.templates.indexOf('ConfirmedDate') + 1, 0, CreatedUTC);
            r.templates.splice(r.templates.indexOf('CreatedUTC') + 1, 0, ConfirmedTime);
            return r;
        },

    }
}
</script>