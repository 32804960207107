<script>
const itineraryBodyDayTitle = importVueComp('components/itinerary', 'ItineraryBodyDayTitle', 'custom');
export default {
    name: 'custom-itinerary-body-day-title',
    mixins: [itineraryBodyDayTitle],
    methods: {
        getDayYear (day) {
            return moment(day.TransDate).format("YYYY");
        },
        /*getDatesText () {
            if (this.day.GroupDays && this.day.GroupDayTo) {
                let r1 = this.getDayMonth(this.day) + ' ' + this.getDayNr(this.day) + ', ' + this.getDayYear(this.day);
                let r2 = '';
                let dayTo = _.find(this.recordStore.BookingDays, (p) => p.DayNr == this.day.GroupDayTo - 1);
                if (dayTo) {
                    r2 = this.getDayMonth(dayTo) + ' ' + this.getDayNr(dayTo) + ', ' + this.getDayYear(dayTo);
                }
                return `${this.tr('From')} ${r1} ${this.tr('TO_')} ${r2}`;
            }
            return this.getDayMonth(this.day) + ' ' + this.getDayNr(this.day) + ', ' + this.getDayYear(this.day);
        },*/
        getDayWeek (day) {
            return tools.toTitleCase(moment(day.TransDate).locale(this.recordStore.Language.Code).format("dddd"));
        },
        getDatesText () {
            if (this.day.GroupDays && this.day.GroupDayTo) {
                let r1 = `${this.getDayWeek(this.day)} ${this.getDayNr(this.day)} ${this.getDayMonth(this.day)} ${this.getDayYear(this.day)}`;
                let r2 = '';
                let dayTo = _.find(this.recordStore.BookingDays, (p) => p.DayNr == this.day.GroupDayTo - 1);
                if (dayTo) {
                    r2 = `${this.getDayWeek(dayTo)} ${this.getDayNr(dayTo)} ${this.getDayMonth(dayTo)} ${this.getDayYear(this.day)} `;
                }
                return `${this.tr('From')} ${r1} ${this.tr('TO_')} ${r2}`;
            }
            return `${this.getDayWeek(this.day)} ${this.getDayNr(this.day)} ${this.getDayMonth(this.day)} ${this.getDayYear(this.day)}`;
        },

   },
}
</script>


