<template>
    <reservation-form
        :id="id"
        :hash="hash"
        :langCode="langCode"
        :paramRequiredFields="requiredFields"
        ref="child"
    ></reservation-form>
</template>

<script>
import '@/extra/custom/css/r-style.scss';
const reservationForm = importVueComp('components/reservationform', 'ReservationForm', 'custom');
let requiredFields = reservationForm.data().requiredFields;
requiredFields.push('pax.RoomTypeId');
requiredFields.splice(requiredFields.indexOf('pax.RoomType'), 1);
export default {
    name: 'reservation-form-custom',
    mixins: [reservationForm],
    components: {
        'reservation-form': reservationForm
    },
    data () {
        return {
            requiredFields: requiredFields,
        }
    },
    methods: {
        getRoomTypesBookingForm () {
            if (this.bookingForm && this.bookingForm.BookingRoomTypes) {
                let roomTypeId = [];
                for (let id in this.bookingForm.BookingRoomTypes) {
                    let room = this.bookingForm.BookingRoomTypes[id];
                    roomTypeId.push({value: id, label: this.tr(room.Name)})
                }
                this.$refs.child.fieldOptions.RoomTypeId = roomTypeId;
                this.$refs.child.fieldOptions = Object.assign({}, this.$refs.child.fieldOptions);
            }
            let model = api.importMixinModule('bookingform', 'model');
            return model.roomTypesBookingForm();
        },
    }

}
</script>
