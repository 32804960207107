<template>
    <div v-if="ready" class="card px-5 py-3 no-border-top">
        <div class="tab-summary">
            <div class="form-row">
                <div class="form-group form-material col-md-1">
                    <select-input
                        v-if="options"
                        fieldName="CurrencyId"
                        :fieldOptions="options"
                        label="Currency"
                        :currentValue.sync="recordStore.SummaryCurrencyId"
                        @change="changeCurrency()"
                    ></select-input>
                </div>
            </div>
            <div class="form-row font-weight-bold">
                <label class="col-5">{{tr('Expenses')}}</label>
                <label class="col-1 text-right">{{tr('Quoted')}}</label>
                <label class="col-1 text-right">{{tr('To Pay')}}</label>
                <label class="col-1 text-right">{{(tr('Paid'))}}</label>
                <label class="col-1 text-right">{{tr('Dif.')}}</label>
                <label class="col-1"></label>
            </div>
            <hr class="my-1">
            <div v-for="(supplier, id) of getters.allSuppliers" class="form-row border-bottom font-weight-bold" :class="getBGClass(id)">
                <label class="col-5">{{supplier.Name}}</label>
                <label class="col-1 text-right" :style="getColor(id, 'cost')">
                    <span v-if="getters.quoted[id]">{{getters.quoted[id].Cost | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}</span>
                </label>
                <label class="col-1 text-right" :style="getColor(id, 'toPay')">
                    {{getters.totalToPay[id] | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-1 text-right" :style="getColor(id, 'paid')">
                    {{getters.totalPaid[id] | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <!--label class="col-1 text-right" v-if="getters.supplierSummary[id].amount">
                    {{(getMarkup(getters.quoted[id].Price, getters.supplierSummary[id].amount)).toFixed(1)}} %
                </label>
                <label v-else class="col-1 text-right">
                </label-->
                <label class="col-1 text-right" :class="{'text-danger': getDiff(id) < 0 }">
                    <span v-if="hasPayments(id)">{{getDiff(id) | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}</span>
                </label>

                <label class="col-3 text-danger" v-if="getters.paidErrors[id] && getters.paidErrors[id].length>0">
                    {{getErrorText(getters.paidErrors[id])}}
                </label>
            </div>
            <div class="form-row font-weight-bold">
                <label class="col-7">{{tr('Other Expenses')}}</label>
                <label class="col-1 text-right" style="color: #2B8E25">
                    {{getters.totalExpenses | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-7">{{tr('Total Expenses')}}</label>
                <!--label class="col-1 text-right">
                    {{totalQuoted | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-1 text-right">
                    {{totalToPay | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label-->
                <label class="col-1 text-right">
                    {{getters.finalCost | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-1 text-right" :class="{'text-danger': totalDiff < 0 }">
                    {{totalDiff | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-6">{{tr('Incomes')}}</label>
                <label class="col-1 text-right">{{tr('To Receive')}}</label>
                <label class="col-1 text-right">{{tr('Received')}}</label>
                <label class="col-1"></label>
            </div>
            <hr class="my-1">
            <div v-for="(receipt, id) in getters.bookingReceipts" class="form-row">
                <label class="col-6">{{receipt.PaymentDate | toDate}}</label>
                <label class="col-1 text-right" :style="getColor(id,  'AmountStyle')">
                    {{receipt.Amount | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
                <label class="col-1 text-right" :style="getColor(id, 'RecAmountStyle')">
                    {{receipt.ReceivedAmount | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="form-row font-weight-bold">
                <label class="col-7">{{tr('Total Incomes')}}</label>
                <label class="col-1 text-right">
                    {{getters.finalIncomes | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}
                </label>
            </div>
            <hr class="my-1">
            <div class="spacer"></div>
            <div class="form-row font-weight-bold">
                <label class="col-6">{{tr('File Profit')}}</label>
                <label class="col-1 text-right">{{((getters.profit / getters.finalCost) * 100).toFixed(1)}} %</label>
                <label class="col-1 text-right">{{getters.profit | formatNumber({c: recordStore.SummaryCurrency})}} {{recordStore.SummaryCurrencyId}}</label>
                <div class="col-1 ml-5" v-if="managerActions">
                    <button type="button" class="btn btn-outline-secondary close-booking-button"
                        @click="closeBooking">
                        {{tr('Close File')}}
                    </button>
                </div>
            </div>
            <hr class="my-1">

            <div class="spacer" v-if="recordStore.ProfitSummary && Object.keys(recordStore.ProfitSummary).length>0">
                <b>
                <div class="form-row bold-row">
                    <label class="col-3 border-bottom">{{tr('Summary')}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Cost">
                    <label class="col-1">{{tr('Outcomes')}}</label>
                    <label class="col-1 text-right">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Cost | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-1">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Incomes">
                    <label class="col-1">{{tr('Incomes')}}</label>
                    <label class="col-1 text-right">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Incomes | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-1">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                <div class="form-row bold-row" v-if="recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Profit">
                    <label class="col-1 border-bottom">{{tr('Profit')}}</label>
                    <label class="col-1 text-right border-bottom">{{recordStore.ProfitSummary[recordStore.SummaryCurrencyId].Profit | formatNumber({c: recordStore.SummaryCurrency})}}</label>
                    <label class="col-1 border-bottom">{{recordStore.SummaryCurrencyId}}</label>
                </div>
                </b>
            </div>
        </div>
    </div>
</template>


<script>
const bookingSummary = importVueComp('components/booking', 'BookingSummary', 'custom');
import { mapState } from "vuex";
export default {
    name: 'custom-booking-summary',
    mixins: [bookingSummary],
    methods: {
        hasPayments (id) {
            let payments = _.filter(api.payments, (r) => r.SupplierId == id);
            if (payments.length == 0) return;
            return true;
        },
        getDiff (id) {
            let p = this.getters.supplierSummary[id].amount;
            return Math.round(this.getters.quoted[id].Cost - p);
        }
    },
    computed: {
        totalDiff () {
            let res = 0;
            for (let id in this.getters.allSuppliers) {
                if (!this.hasPayments(id)) continue;
                let p = this.getters.supplierSummary[id].amount;
                res += this.getters.quoted[id].Cost - p;
            }
            return Math.round(res);
        },
    }
};
</script>
