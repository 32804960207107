<script>
const bookingPayments = importVueComp('components/booking', 'BookingPayments', 'custom');
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'custom-booking-payments',
    mixins: [bookingPayments],
    computed: {
        paymentsAmount () {
            let res = 0;
            if (!this.payments) return res;
            for (let p of this.payments) {
                if (!p.CurrencyId) continue;
                if (!p.Amount) continue;
                if (p.CurrencyId == this.recordStore.CurrencyId ) {
                    res += p.Amount;
                } else {
                    res += this.recordStore.currencyConvert(p.CurrencyId, this.recordStore.CurrencyId, p.Amount);
                }

            }
            return res;
        },
    },
    methods: {
        async newPayment () {
            if (!this.supplierId) return;
            let model = await api.importMixinModule('bookingpayment', 'model');
            let newRecord = await new model({tableName: 'bookingpayment'});
            newRecord.SupplierId = this.supplierId;
            newRecord.BookingId = this.recordStore.id;
            if (this.supplierData && this.supplierData.Totals) {
                newRecord.Amount = this.supplierData.Totals.Cost - this.paymentsAmount;
                newRecord.CurrencyId = this.recordStore.CurrencyId;
            }
            EventBus.$emit('open-modal-record', {modalRecord: newRecord, modalTable: 'bookingpayment',
                modalId: 'new', closeAction: 'close-payment'});
        },
    }
}
</script>

