<template>
    <div class="card no-border-top">
        <div class="card-header">
            <div class="form-row">
                <div class="col-12 mb-1 px-3">
                    <div class="form-row">
                        <div class="form-group col-12 mb-0">
                            <div class="card-header section-title col-12 py-1">
                                <label>{{tr('Important Notes')}}</label>
                            </div>
                         </div>
                    </div>
                    <text-area-style
                        :text.sync="recordStore.ImportantNotes"
                        :pasteText="recordStore._ImportantNotes"
                        maxHeight="200"
                    ></text-area-style>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'important-notes',
    components: {
    },
    data () {
        return {
        }
    },
    async mounted () {
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    methods: {
    },
}
</script>
