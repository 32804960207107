<script>
const bookingButtonActions = importVueComp('components/booking', 'BookingButtonActions', 'custom');

export default {
    name: 'custom-booking-button-actions',
    mixins: [bookingButtonActions],
    data () {
        return {
            customButtons: [{
                click: this.openTripReview,
                show: () => {
                    return this.recordStore.TemplateType=='NONE' && this.recordStore.id;
                },
                title: tr('Trip Review'),
                icon: 'receipt',
            }]
        }
    },
    methods: {
        openTripReview: function() {
            let route = this.$router.resolve({
                name: 'trip-review',
                params: {
                    hash: this.recordStore.Hash,
                    id: this.recordStore.id,
                }
            })
            console.log(route)
            window.open(route.href, '_blank');
        }
    },

}
</script>
