const TripReview = importVueComp('components/booking', 'TripReview');

function getCustomRoutes () {
    let res = [];
    res.push({
        path: '/public/tripreview/:hash/:id',
        name: 'trip-review',
        component: TripReview,
        props: true,
        meta: {title: 'Innovart', icon: 'custom_favicon.ico', public: true},
    })
    let remove = []
    return {routes: res, remove: remove};
}

export default getCustomRoutes();