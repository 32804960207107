<script>
import Vue from 'vue';
import { mapState } from 'vuex';

let bookingCoverMainExtras = Vue.component('booking-cover-main-extras', {
    props: [],
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    template: `
            <div class="form-row">
                <div class="px-3 col-md-3">
                    <input-field
                        :field="recordStore.fieldsObject.Reference"
                        :defValue.sync="recordStore.Reference"
                        :readonly="true"
                    ></input-field>
                </div>
                <div class="px-3 col-md-3">
                    <select-input fieldName="CompanyId"
                        :field="recordStore.fieldsObject.CompanyId"
                        label="Company"
                        :currentValue.sync="recordStore.CompanyId"
                    ></select-input>
                </div>
                <div class="px-3 col-md-3" v-if="recordStore.bookingConfirmed">
                    <div class="form-group form-default mb-0" style="margin-top: -6px">
                        <label class="l-date mb-0 text-small" >Fecha de Cotización</label>
                        <m-date-time type="datetime" :type="'date'"
                            :value.sync="recordStore.CreatedUTC" format="YYYY-MM-DDTHH:mm:ss">
                        </m-date-time>
                    </div>
                </div>
                <div class="px-3 col-md-3" v-if="recordStore.bookingConfirmed">
                    <input-field
                        :field="recordStore.fieldsObject.ConfirmedDate"
                        :defValue.sync="recordStore.ConfirmedDate"
                    ></input-field>
                </div>
                <div class="px-3 col-md-3">
                    <input-field
                        :field="recordStore.fieldsObject.NoReports"
                        :defValue.sync="recordStore.NoReports"
                    ></input-field>
                </div>
            </div>
        </template>
    `,
})



const bookingCoverMain = importVueComp('components/booking', 'BookingCoverMain', 'custom');
export default {
    name: 'contact-booking-cover-main',
    mixins: [bookingCoverMain],
    mounted () {
        this.extraComp.push({
            component: bookingCoverMainExtras,
            ref: 'bookingCoverMainExtras',
            change: () => {},
        })
    },
}
</script>

<style scoped>
.l-date{
    top: -42px;
    position: relative;
}
</style>

