<script>
const priceBreakdown = importVueComp('components/booking', 'PriceBreakdown');
export default {
    name: 'custom-price-breakdown',
    mixins: [priceBreakdown],
    data () {
        return {
            flightMessage: `
                Non reimbursable rates. Prices can change till the moment of issuing, rates are
                always subject to availability at the time of the booking request.
                Aerolineas Argentinas flights include: One up to 15k checked suitcase + one carry on up to 8k +
                one personal item. (15k is the maximum weight that Aerolineas Airline allows to purchase
                in advance, if clients have a 23k suitcase they will have to pay the extra weight at the check
                in desk at the airport the day of the flight, this is Aerolineas Argentinas policy,
                check more about it on this
                <a href="https://www.aerolineas.com.ar/en-us/baggage-allowance-usa?tab=excess-baggage" target="_blank"> link </a>).
                Latam flights include: One up to 23k checked suitcase + one carry on up to 8k + one personal item.

            `
        }
    },
}
</script>

