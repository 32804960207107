<template>
    <div id="b-invoice" v-if="ready" @contextmenu="handleClick($event)">
        <div class="button-fixed">
            <button type="button" class="btn btn-primary action-button"
                @click="print()">
                <font-awesome-icon icon="print"/>
            </button>
        </div>
        <div class="container container-body">
            <div class="row mb-5">
                <div class="col-6 row">
                    <div class="col-12">
                        <img width="200" id="logo" src="@/extra/custom/img/logo-full.png" class="">
                    </div>
                </div>
                <div class="col-6 row align-items-center">
                    <div class="col-12">
                        <h2>
                            {{getDocName}}
                        </h2>
                    </div>
                    <div class="col-12">
                        {{getDocName}} Number: {{getNumber}}
                    </div>
                    <div class="col-12">
                        Date: {{getDate(new Date())}}
                    </div>
                    <div class="col-12">
                        Id. de cliente: {{booking.PaxName}}
                    </div>
                    <div class="col-12" v-if="receipt && receipt.PaymentDate">
                        Payment date: {{getDate(receipt.PaymentDate)}}
                    </div>
                </div>

                <div class="col-6 row" v-if="company">
                    <div class="col-12 row mt-3">
                        <div class="col-12">
                            {{company.Name}}
                        </div>
                        <div class="col-12">
                            {{company.Address}}
                        </div>
                        <div class="col-12" v-if="company.Email">
                            <a :href="'mailto:' + company.Email">
                                {{company.Email}}
                            </a>
                        </div>
                        <div class="col-12">
                            {{company.Phone}}
                        </div>
                    </div>
                </div>
                <div class="col-6 row" v-if="booking.Agency">
                    <div class="col-12 row mt-3">
                        <div class="col-12">
                            {{booking.Agency.CompanyName}}
                        </div>
                        <div class="col-12" v-for="row of agencyAddress">
                            {{row}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5 mr-5">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12" v-if="booking.SalesUser">
                            Seller: {{booking.SalesUser.FirstName}} {{booking.SalesUser.LastName}}
                        </div>
                    </div>
                    <hr class="my-2 hr-strong">
                    <div class="row mt-3">
                        <div class="col-2">
                            1
                        </div>
                        <div class="col-8">
                            <span v-if="receipt">{{receipt.Concept}}</span>
                        </div>
                        <div class="col-2 text-right">
                            {{amount}}
                        </div>
                    </div>
                    <div class="row mt-5 mb-5">
                    </div>
                    <div class="row mt-5 mb-5">
                    </div>
                    <div class="row mt-5 mb-5">
                    </div>
                    <div class="row mt-3">
                        <div class="col-3 offset-7" v-if="receipt && receipt.PaymentStatus == 'PENDING'">
                            TOTAL TO BE PAID
                        </div>
                        <div class="col-3 offset-7" v-else>
                            PAID
                        </div>
                        <div class="col-2 text-right">
                            {{amount}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5 mr-5" v-if="booking && booking.Company">
                <div class="col-12" v-for="row of bankDataRows">
                    {{row}}
                </div>
            </div>

        </div>
        <footer id="footer" class="not-print fixed-bottom d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <strong class="copyright">
                            <i class="fa fa-copyright"></i>
                            <span class="copyright_text"> &copy; Copyright 2017 - INNOVART - NATALIA MARIELA BOSSIO </span>
                        </strong>
                    </div>
                    <div class="col-lg-6 text-right">
                         <img src="http://innovartours.com/wp-content/uploads/2017/01/visa-1.png.webp" alt="">
                         <img src="http://innovartours.com/wp-content/uploads/2017/01/mastercard-1.png.webp" alt="">
                         <img src="http://innovartours.com/wp-content/uploads/2017/01/America-24.png.webp" alt="">
                         <img src="http://innovartours.com/wp-content/uploads/2017/01/bank-transfer-1.png.webp" alt="">
                    </div>
                </div>
            </div>
        </footer>
        <div class="divFooter">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <strong class="copyright">
                            <i class="fa fa-copyright"></i>
                            <span class="copyright_text"> &copy; Copyright 2017 - INNOVART - NATALIA MARIELA BOSSIO </span>
                        </strong>
                    </div>
                    <div class="col-lg-6 text-right">
                         <img src="http://innovartours.com/wp-content/uploads/2017/01/visa-1.png.webp" alt="">
                         <img src="http://innovartours.com/wp-content/uploads/2017/01/mastercard-1.png.webp" alt="">
                         <img src="http://innovartours.com/wp-content/uploads/2017/01/America-24.png.webp" alt="">
                         <img src="http://innovartours.com/wp-content/uploads/2017/01/bank-transfer-1.png.webp" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import '@/extra/custom/css/invoice.scss';
let bookingInvoice = importVueComp('components/documentation', 'BookingInvoice', 'custom');
export default {
    name: 'custom-booking-invoice',
    mixins: [bookingInvoice],
    data () {
        return {
            receipts: [],
        }
    },
    methods: {
        async setReceipts () {
            await api.setReceipts(this.recordStore.id);
            this.receipts = api.receipts;
        },
    },
    computed:{
        company () {
            if (this.receipt && this.receipt.Company) return this.receipt.Company;
        },
        receipt () {
            return _.find(this.receipts, (r) => r.id == this.payment);
        },
        bankDataRows () {
            if (this.booking && this.booking.Company && this.booking.Company.BankData) {
                return this.booking.Company.BankData.split('\n');
            }
            return [];
        }
    },
    watch: {
        ready () {
            if (this.ready) {
                this.setReceipts();
            }
        }
    }
}

</script>

<style scoped lang="scss">

#footer {

    min-height: 60px;
    background: #252525;
    color: #9d9d9d !important;

    .copyright {
        font-weight: normal;
        letter-spacing: 0.03em;
        font-size: 0.9em  !important;
        line-height: 1;
        font-family: 'Mulish' !important;
    }

}

</style>