<template>
    <form class="form-inline celeste header-row" action="">
       <div-input fieldName="pax.FirstName" :v.sync="pax.FirstName" :cols="2"
            label="First Name" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.MiddleName" :v.sync="pax.MiddleName" :cols="2"
            label="Second Name" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.LastName" :v.sync="pax.LastName" :cols="2"
            label="Surname" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.Email" :v.sync="pax.Email" :cols="3"
            label="Email" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div class="form-group col-md-3">
            <date-selector
                :def.sync="pax.BirthDate"
                format="YYYY-MM-DD"
                :id="'BirthDate-' + i"
                :label="tr('Date of Birth') + '*'"
                :langCode="langCode"
                :disableFuture="true"
                :invalid.sync="datesInvalids['BirthDate-' + i]"
          ></date-selector>
       </div>
       <div-input fieldName="pax.Passport" :v.sync="pax.Passport" :cols="2"
            label="Valid Passport Number" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div class="form-group col-md-3">
            <date-selector
                :def.sync="pax.PassportValidTo"
                format="YYYY-MM-DD"
                :id="'PassportValidTo-' + i"
                :label="tr('Passport Expiration Date') + '*'"
                :langCode="langCode"
                :disablePast="true"
                :invalid.sync="datesInvalids['PassportValidTo-' + i]"
          ></date-selector>
       </div>

       <div class="form-group col-md-3">
            <div class="label">{{tr('Country of Origin of Passport')}}*</div>
            <select class="custom-select"
                :class="(!pax.PassportCountry && invalid && isRequired('pax.PassportCountry'))? 'is-invalid': ''"
                :required="isRequired('pax.PassportCountry')"
                v-model="pax.PassportCountry">
                <option
                    v-for="opt of countries"
                    :value="opt.value"
                    :selected="opt.value == pax.PassportCountry">
                    {{tr(opt.label)}}
                </option>
          </select>
       </div>

       <div class="form-group col-md-2">
            <div class="label">{{tr('Room Type?')}}*</div>
            <select class="custom-select"
                :class="(!pax.RoomTypeId && invalid && isRequired('pax.RoomTypeId'))? 'is-invalid': ''"
                :required="isRequired('pax.RoomTypeId')"
                v-model="pax.RoomTypeId">
                <option
                    v-for="opt of fieldOptions['RoomTypeId']"
                    :value="opt.value"
                    :selected="opt.value == pax.RoomTypeId">
                    {{tr(opt.label)}}
                </option>
          </select>
       </div>
       <div class="form-group col-md-2" v-if="pax.Room.Pax == 2">
            <div class="label">{{tr('DBL Type?')}}*</div>
            <select class="custom-select"
                :class="(!pax.RoomType && invalid && isRequired('pax.RoomType'))? 'is-invalid': ''"
                :required="isRequired('pax.RoomType')"
                v-model="pax.RoomType">
                <option
                    v-for="opt of fieldOptions['RoomType']"
                    :value="opt.value"
                    :selected="opt.value == pax.RoomType">
                    {{tr(opt.label)}}
                </option>
          </select>
       </div>

       <div-input fieldName="pax.Allergies" :v.sync="pax.Allergies" :cols="6"
            label="Allergies? If so, what?" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.Medication" :v.sync="pax.Medication" :cols="6"
            label="Medication" :langCode="langCode" :invalid="invalid"
       ></div-input>
       <div-input fieldName="pax.Dietary" :v.sync="pax.Dietary" :cols="6"  :invalid="invalid"
            label="Dietary Restrictions (Celiac, Vegan, Diabetic. etc)" :langCode="langCode"
       ></div-input>
       <div class="form-group col-md-6">
         <div class="label">{{tr('Add Passport')}}</div>
         <input type="file" class="form-control" @change="uploadPassport($event, i)" :id="'pass-' + i" multiple>
       </div>
    </form>
</template>

<script>

const reservationFormPax = importVueComp('components/reservationform', 'ReservationFormPax', 'custom');
export default {
    name: 'custom-reservation-form-pax',
    mixins: [reservationFormPax],
}
</script>

