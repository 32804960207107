<script>
const itineraryHeaderBar = importVueComp('components/itinerary', 'ItineraryHeaderBar', 'custom');
export default {
    name: 'custom-itinerary-header-bar',
    mixins: [itineraryHeaderBar],
    data () {
        return {
            showWord: true,
        }
    },
}
</script>
