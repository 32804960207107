<template>
    <footer id="footer" class="not-print fixed-bottom d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <strong class="copyright">
                        <i class="fa fa-copyright"></i>
                        <span class="copyright_text"> &copy; Copyright 2017 - INNOVART - NATALIA MARIELA BOSSIO </span>
                    </strong>
                </div>
                <div class="col-lg-6 text-right">
                     <img src="https://innovartours.com/wp-content/uploads/2017/01/visa-1.png.webp" alt="">
                     <img src="https://innovartours.com/wp-content/uploads/2017/01/mastercard-1.png.webp" alt="">
                     <img src="https://innovartours.com/wp-content/uploads/2017/01/America-24.png.webp" alt="">
                     <img src="https://innovartours.com/wp-content/uploads/2017/01/bank-transfer-1.png.webp" alt="">
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-footer',
}
</script>


<style scoped lang="scss">

#footer {

    min-height: 60px;
    background: #252525;
    color: #9d9d9d !important;

    .copyright {
        font-weight: normal;
        letter-spacing: 0.03em;
        font-size: 0.9em  !important;
        line-height: 1;
        font-family: 'Mulish' !important;
    }

}

</style>