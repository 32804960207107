<template>
    <div v-if="ready" class="app-index">
        <div class="d-flex align-items-center justify-content-center mt-3 mb-3">
            <h3>{{this.recordStore.Title}}</h3>
        </div>

        <div class="container container-body">
            <table class="table table-striped  table-sm table-bordered">
              <thead>
                <tr class="">
                    <td class="text-center" colspan="5">
                        {{tr('Services')}}
                    </td>
                </tr>
                <tr class="">
                    <td class="col-date text-center">
                        {{tr('Date')}}
                    </td>
                    <td class="px-2 col-city">
                        {{tr('Location')}}
                    </td>
                    <td class="px-2 col-name">
                        {{tr('Description')}}
                    </td>
                    <td class="px-2">
                        {{tr('Res.Code')}}
                    </td>
                    <td class="px-2">
                        {{tr('Pick Up')}}
                    </td>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="s of services">
                    <td class="col-date text-center">
                        {{getDate(s.TransDate)}}
                    </td>
                    <td class="px-2 col-city">
                        <span>{{getCity(s)}}</span>
                    </td>
                    <td class="px-2 col-name cursor-pointer" @click="showService(s)">
                        {{s.Name || s.Service.Name}}
                    </td>
                    <td class="px-2">
                        {{s.ReservationNumber}}
                    </td>
                    <td class="px-2">
                        {{s.PickupInfo}}
                    </td>
                </tr>
              </tbody>
            </table>
            <table class="mt-3 table table-striped  table-sm table-bordered" v-if="rooms.length > 0">
              <thead>
                <tr class="" >
                    <td class="text-center" colspan="5">
                        {{tr('Hotels')}}
                    </td>
                </tr>
                <tr class="">
                    <td class="col-date text-center">
                        {{tr('Date In')}}
                    </td>
                    <td class="col-date text-center">
                        {{tr('Date Out')}}
                    </td>
                    <td class="px-2">
                        {{tr('Location')}}
                    </td>
                    <td class="px-2">
                        {{tr('Description')}}
                    </td>
                    <td class="px-2">
                        {{tr('Res.Code')}}
                    </td>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="room of rooms">
                    <td class="col-date text-center">
                        {{getDate(room.TransDate)}}
                    </td>
                    <td class="col-date text-center">
                        {{getDateOut(room)}}
                    </td>
                    <td class="px-2" v-if="room.Hotel && room.Hotel.City">
                        <span>{{room.Hotel.City.Name}}</span>
                    </td>
                    <td class="px-2 cursor-pointer" @click="showHotel(room.Hotel)">
                        <span v-if="room.Hotel">{{room.Hotel.Name}}</span>
                        <span v-if="room.Hotel && room.Hotel.HotelCategory"> ({{room.Hotel.HotelCategory.Name}})</span>
                        <span v-if="room.Room"> {{room.Room.Name}} </span>
                        <span v-if="room.Category"> {{room.Category.Name}}</span>
                    </td>
                    <td class="px-2">
                        {{room.ReservationNumber}}
                    </td>
                </tr>
              </tbody>
            </table>


            <table class="mt-3 table table-striped  table-sm table-bordered" v-if="flights.length > 0">
              <thead>
                <tr class="" >
                    <td class="text-center">
                        {{tr('Flights')}}
                    </td>
                </tr>
                <tr class="">
                    <td class="col-date text-center">
                        {{tr('Itinerary')}}
                    </td>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="flight of flights">
                    <td class="col-date text-center">
                        <span v-if="!recordStore.bookingConfirmed">Suggested Domestical Flight:</span>
                        <div v-for="f of getFlightsList(flight)">
                            {{f}}
                        </div>
                        Non reimbursable rates. Prices can change till the moment of issuing, rates are
                        always subject to availability at the time of the booking request.
                        Aerolineas Argentinas flights include: One up to 15k checked suitcase + one carry on up to 8k +
                        one personal item. (15k is the maximum weight that Aerolineas Airline allows to purchase
                        in advance, if clients have a 23k suitcase they will have to pay the extra weight at the check
                        in desk at the airport the day of the flight, this is Aerolineas Argentinas policy,
                        check more about it on this
                        <a href="https://www.aerolineas.com.ar/en-us/baggage-allowance-usa?tab=excess-baggage" target="_blank"> link </a>).
                        Latam flights include: One up to 23k checked suitcase + one carry on up to 8k + one personal item.
                    </td>
                </tr>
              </tbody>
            </table>
            <trip-review-prices
            ></trip-review-prices>

            <price-breakdown-service
                v-if="service"
                :s.sync="service"
                :title="serviceTitle"
            ></price-breakdown-service>
            <price-breakdown-hotel
                v-if="hotel"
                :hotel.sync="hotel"
                :title="hotelTitle"
                :languageId="recordStore.LanguageId"
            ></price-breakdown-hotel>
        </div>
    </div>
</template>

<script>
const priceBreakdownService = importVueComp('components/booking', 'PriceBreakdownService');
const priceBreakdownHotel = importVueComp('components/booking', 'PriceBreakdownHotel');
const tripReviewPrices = importVueComp('components/booking', 'TripReviewPrices');
import { mapState } from 'vuex';
const uuidv1 = require('uuid/v1');
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'trip-review-details',
    props: ['id', 'hash'],
    components: {
        'price-breakdown-service': priceBreakdownService,
        'price-breakdown-hotel': priceBreakdownHotel,
        'trip-review-prices': tripReviewPrices
    },
    data () {
        return {
            ready: false,
            services: [],
            rooms: [],
            service: null,
            hotel: null,
            serviceTitle: null,
            hotelTitle: null,
            flights: [],
            message: null,
            processing: false,
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
    },
    async mounted (){
        let services = [];
        this.request = 0;
        for (let day of this.recordStore.BookingDays) {
            for (let s of day.BookingDayServices) {
                s.TransDate = day.TransDate;
                if (s.Optional) s.Include = false;
                services.push(s);
            }
            for (let hotel of day.BookingDayHotels) {
                for (let room of hotel.BookingDayRooms) {
                    room.TransDate = day.TransDate;
                    room.Days = hotel.Days
                    this.rooms.push(room);
                }
            }
        }
        let flights = [];
        for (let flight of this.recordStore.BookingFlights) {
            flights.push(flight)
        }
        this.flights = flights;
        this.services = services;
        this.ready = true;
        api.setProcessing(false)
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getDate (d) {
            if (!d) return '';
            if (!this.recordStore.Language) return;
            let r = moment(d).local().locale(this.recordStore.Language.Code).format('ddd DD MMM YY')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        getDateOut (room) {
            if (!room) return '';
            if (!room.TransDate) return '';
            let d = room.TransDate;
            d = moment(d).add(room.Days, "days");
            if (!this.recordStore.Language) return;
            let r = moment(d).local().locale(this.recordStore.Language.Code).format('ddd DD MMM YY')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        print () {
            print()
        },
        getCity (s) {
            if (s.CityId) return s.City.Name;
            if (s.Service && s.Service.City) return s.Service.City.Name;
        },
        showService (s) {
            this.service = s;
            this.serviceTitle = s.Name || s.Service.Name;
        },
        showHotel (h) {
            this.hotel = h;
            this.hotelTitle = h.Name;
        },
        getFlightsList (f) {
            let res = [];
            if (f.Output) {
                let rows = f.Output.split('\n');
                for (let r of rows){
                    res.push(r);
                }
            }
            return res;
        },
    },

}
</script>

<style scoped>
    thead tr {
        background-color: #d6d8db;
        font-weight: 700;
    }
    .color-blue {
        color: blue;
    }
    a {
        color: blue !important;
    }
</style>