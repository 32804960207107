<template>
    <div id="pstyles" v-if="recordStore && !processing">
        <itinerary
            v-if="!processing && !recordStore.DisableLinks"
            :view="true"
            :id="id"
            :version="version"
            :showMap="true"
        ></itinerary>
        <div v-else-if="!processing" class="mt-5 container pt-2">
            <itinerary-header-bar
                :view="true"
            ></itinerary-header-bar>
            <div class="form-row mt-5">
                <div class="col-12 mt-5">
                    ITINERARY DISABLED
                </div>
            </div>
        </div>
        <itinerary-footer></itinerary-footer>
    </div>
</template>

<script>
const itineraryView = importVueComp('components/itinerary', 'ItineraryView', 'custom');
const itineraryHeaderBar = importVueComp('components/itinerary', 'ItineraryHeaderBar');
export default {
    name: 'custom-itinerary-view',
    mixins: [itineraryView],
    components: {
        'itinerary-header-bar': itineraryHeaderBar,
    },

}
</script>
