<script>
const abmBooking = importVueComp('components/tools', 'abmBooking', 'custom')
export default {
    name: 'custom-abm-booking',
    mixins: [abmBooking],
    mounted () {
        if (this.sales) {
            this.customFilters.push({
            name: 'Confirmed',
            editor: 'checkbox',
            label: 'Solo confirmados',
            callback: () => {
                this.getAbm();
                }
            })
            this.filtersValues.Confirmed = true;
        }
    },
    computed: {
        list () {
            let res = this.abm.abmList();
            if (this.filtersValues.DateInnFrom) {
                res = _.filter(res, (r) => {
                    return r.StartDate >= this.filtersValues.DateInnFrom;
                })
            }
            if (this.filtersValues.DateInnTo) {
                res = _.filter(res, (r) => {
                    return r.StartDate <= this.filtersValues.DateInnTo;
                })
            }
            if (this.filtersValues.Confirmed) {
                let date = moment(new Date()).format('YYYY-MM-DD');
                res = _.filter(res, (r) => {
                    if (r.Status == 'TRAVELED') return false;
                    if (r.Status == 'CANCELED') return false;
                    if (r.EndDate < date) return false;
                    return true;
                })
            }
            return res;
        },
    },
    methods: {
        getColumns () {
            let columns = Object.assign([], this.abm.columnsNames);
            if (this.options.fieldsAbmList) {
                columns = Object.assign([], this.options.fieldsAbmList);
            }
            columns.splice(0, 0, 'Reference');
            columns.splice(columns.indexOf('AgencyId'), 1);
            columns.splice(1, 0, 'AgencyId');
            if (this.$router.currentRoute.name == "abm-booking") {
                columns.splice(columns.indexOf('AgencyId') + 1, 0, 'PaxQuantity');
                columns.splice(columns.indexOf('LanguageId'), 1);
            }
            if (!this.sales) {
                columns.splice(3, 1, 'ContactId');
                columns.splice(columns.indexOf('PaxName'), 1);
                columns.splice(columns.indexOf('Title') + 1, 0, 'SubTitle');
            } else {
                columns.push('Total');
                columns.push('EndDate');
                columns.splice(columns.indexOf('Title') + 1, 0, 'OperUserId');
                columns.splice(columns.indexOf('LanguageId'), 1);
                columns.splice(columns.indexOf('SalesUserId'), 1);
                columns.splice(columns.indexOf('SalesOriginId'), 1);
            }
            this.columnsNames = columns;
            columns.splice(columns.indexOf('id'), 1);
            columns.splice(columns.indexOf('Title'), 1);
        },
        getColumnsClasses () {
            let res = {};
            for (let field of this.abm.fields) {
                if (field.editor == 'number') {
                    res[field.name] = 'text-right';
                }
            }
            res.Total = 'text-right no-wrap'
            return res;
        },

    },
}
</script>