<template>
  <section id="pricing" class="pricing" v-if="recordStore">
    <div class="container">
      <div class="row gy-4" >
          <itinerary-prices
              v-if="!recordStore.HideTotalPrice"
          ></itinerary-prices>
          <div class="col-sm-12 mt-4" :class="flightsCol" v-if="recordStore.flightsListInternal.length">
            <div class="box">
              <h3>{{tr('Internal operational flights')}}</h3>
              <ul>
                <li>
                    <span class="" v-for="(f, fId) of recordStore.flightsListInternal" :key="fId" v-if="f && f.length > 0">
                        {{f}}<br>
                    </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-12 mt-4" :class="flightsCol" v-if="recordStore.flightsListInternational.length">
            <div class="box">
              <h3>{{tr('International flights')}}</h3>
              <ul>
                <li>
                    <span class="ri" v-for="(f, fId) of recordStore.flightsListInternational" :key="fId">
                        {{f}}
                    </span>
                 </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="box">
                <div class="box-header">
                  <h3>{{tr("Prices include")}}</h3>
                </div>
                <div class="box-body box-include">
                  <text-rows-style tag="p" :text="recordStore.Include"></text-rows-style>
                </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="box">
                <div class="box-header">
                  <h3>{{tr("Prices DON'T include")}}</h3>
                </div>
                <div class="box-body box-not-include">
                  <text-rows-style tag="p" :text="recordStore.NotInclude"></text-rows-style>
                </div>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 mt-4" v-if="ifText(recordStore.Comment)">
            <div class="box">
                <div class="box-header">
                  <h3>{{tr("Observations")}}</h3>
                </div>
                <div class="box-body box-not-include">
                  <text-rows-style tag="p" :text="recordStore.Comment"></text-rows-style>
                </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
const itineraryTerms = importVueComp('components/itinerary', 'ItineraryTerms', 'custom');
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-terms',
    mixins: [itineraryTerms]
}
</script>


