<template>
    <div class="clearfix mx-3 mb-4 mt-2">
        <div class="float-left" v-if="canAddService">
            <button type="button" class="btn btn-outline-success btn-action-day mx-1"
                @click="showServiceSelector()">
                <i class="mr-2" aria-hidden="true">
                    <font-awesome-icon icon="plus"/>
                </i>{{tr('Add Service')}}
            </button>

            <button type="button" class="btn btn-outline-success btn-action-day mx-1" @click="addBlankService()">
                <i class="mr-2" aria-hidden="true">
                    <font-awesome-icon icon="plus"/>
                </i>{{tr('Add Free Service')}}
            </button>
            <button type="button" class="btn btn-outline-success btn-action-day mx-1" v-if="bookingConfirmed"
                @click="showDayText = true"> Notas
            </button>
        </div>
        <div class="float-right" v-if="canRemove">
            <i class="cursor-pointer mr-3" @click="removeDay">
                <font-awesome-icon icon="trash-alt"/>
            </i>
        </div>

        <input-value
            v-if="showDayText"
            :show.sync="showDayText"
            @save="saveDayText"
            type="memo"
            :def="recordStore.BookingDays[dayId].DocumentationText"
            text="Editar Texto para Documentación">
        </input-value>
        <service-selector-modal
            v-if="serviceSelector"
            :dayNr="dayNr"
            :language="recordStore.languageName"
            :city="getCityId"
            @addServices="addServices"
            @addTemplate="addTemplateServices"
            @close="closeServiceSelector"
        ></service-selector-modal>
        <booking-day-settings
            v-if="daySettings"
            :compressView="compressView"
            :dayId="dayId"
            :dayNr="dayNr"
            @close="daySettings = false"
        ></booking-day-settings>

    </div>
</template>

<script>
const bookingDayButtons = importVueComp('components/booking', 'BookingDayButtons', 'custom');
export default {
    name: 'custom-booking-day-buttons',
    mixins: [bookingDayButtons],
    data () {
        return {
            showDayText: false,
        }
    },
    mounted () {
    },
    computed:{
        bookingConfirmed () {
            if (this.recordStore && this.recordStore.bookingConfirmed) return true;
        }
    },
    methods: {
        saveDayText (t) {
            this.recordStore.BookingDays[this.dayId].DocumentationText = t;
        }
    }
}
</script>



