<template>
  <section id="pricing" class="pricing" v-if="recordStore">
    <div class="container">
      <div class="row gy-4" >
          <itinerary-prices
          ></itinerary-prices>
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="box">
                <div class="box-header">
                  <h3>{{tr("Prices include")}}</h3>
                </div>
                <div class="box-body box-include">
                  <text-rows-style tag="p" :text="recordStore.Include"></text-rows-style>
                </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="box">
                <div class="box-header">
                  <h3>{{tr("Prices DON'T include")}}</h3>
                </div>
                <div class="box-body box-not-include">
                  <text-rows-style tag="p" :text="recordStore.NotInclude"></text-rows-style>
                </div>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 mt-4" v-if="ifText(recordStore.Comment)">
            <div class="box">
                <div class="box-header">
                  <h3>{{tr("Observations")}}</h3>
                </div>
                <div class="box-body box-not-include">
                  <text-rows-style tag="p" :text="recordStore.Comment"></text-rows-style>
                </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
const itineraryPrices = importVueComp('components/itinerary', 'ItineraryPrices');
import { mapState } from 'vuex';
export default {
    name: 'itinerary-terms',
    props: ['expanded'],
    components: {
      'itinerary-prices': itineraryPrices,
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        flightsCol () {
            if (this.recordStore.flightsListInternal.length && !this.recordStore.flightsListInternational.length) return 'col-lg-12';
            if (!this.recordStore.flightsListInternal.length && this.recordStore.flightsListInternational.length) return 'col-lg-12';
            return 'col-lg-6';
        }
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        ifText (s) {
            return tools.ifText(s);
        },
    },
}
</script>


<style scoped>
    .box {
        padding: 40px 20px !important;
    }
</style>