<template>
    <div v-if="readMore || textLength.start < limit">
        <text-rows-style
            class="italic text-justify mb-0"
            :text="text"
            v-if="s && ifText && !edit"
            @edit="setEdit"
        ></text-rows-style>
        <text-area-edit-style
            v-else-if="s && edit"
            :text.sync="s.Description"
         ></text-area-edit-style>
    </div>
</template>

<script>
const itineraryBodyService = importVueComp('components/itinerary', 'ItineraryBodyService', 'custom');
export default {
    name: 'custom-itinerary-body-service',
    mixins: [itineraryBodyService],
}
</script>


