<script>
const bookingNavItems = importVueComp('components/booking', 'BookingNavItems', 'custom');
export default {
  name: 'custom-booking-nav-items',
  extends: bookingNavItems,
  methods: {
    getItems () {
      this.itemsList = this.getItemList();
      let mailsItem = _.find(this.itemsList, (r) => r.id == 'mails');
      if (mailsItem) {
        mailsItem.vIf = () => {
            return !this.isTemplate && !this.version;
        };
      }
      this.itemsList.push({
        id: 'importantNote',
        label: 'Important Notes',
        vIf: () => { return !this.isTemplate },
        showTabs: () => { return this.showTabs }
      });
      this.setItems();
    }
  }
}
</script>

