<template>
    <div>
        <booking-quote-config></booking-quote-config>
        <div class="form-row col-md-8 form-material">
            <div class="form-row col-md-9">
                <div class="col-md-4 px-2">
                    <number-input
                        class="mt-0"
                        :v.sync="record.HotelMarkup"
                        label="Hotel Mkp"
                        d="0"
                        @change="change('HotelMarkup')"
                    ></number-input>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteConfig = importVueComp('components/booking', 'BookingQuoteConfig', 'custom');
export default {
    name: 'custom-booking-quote-config',
    mixins: [bookingQuoteConfig],
    components: {
        'booking-quote-config': bookingQuoteConfig,
    },
}
</script>

